const resource = {
    en: {
        heading: 'Reset Password Mail Requested',
        message: 'Your Password was successfully reset.',
        login_button: 'Login'
    },
    de: {
        heading: 'Rücksetzung Ihres Passwortes angefordert',
        message: 'Ihr Passwort wurde erfolgreich geändert.',
        login_button: 'Anmelden'
    }
}

export default resource;